import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import FAQ from './pages/FAQ';
import Product from './pages/Product';
import Testimonial from './pages/Testimonial';
import './App.css';
import { Container } from 'react-bootstrap';


function App() {
  const [isOver21, setIsOver21] = useState(false);

  useEffect(() => {
    const isOver21Cookie = localStorage.getItem('isOver21');
    if (isOver21Cookie) {
      setIsOver21(true);
    }
  }, []);

  const handleOver21Confirmation = () => {
    localStorage.setItem('isOver21', 'true');
    setIsOver21(true);
  };

  const handleUnder21Confirmation = () => {
    window.location.href = 'https://www.google.com';
  };



  return (
    <div fluid style={{ color: 'black', minHeight: '100vh', minWidth: '100vw' }}>
      {!isOver21 && (
        <div className="confirmation">
          <h2>21+ Confirmation</h2>
          <p>Please confirm that you are 21 years or older to proceed.</p>
          <button className="confirmation-button" onClick={handleOver21Confirmation}>I am 21+</button>
          <button className="nconfirmation-button" onClick={handleUnder21Confirmation}>I am not 21+</button>

        </div>
      )}
      {isOver21 && (
        <>
          <NavbarComponent />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/product" element={<Product />} />
              <Route path="/testimonial" element={<Testimonial />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
