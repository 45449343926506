import React from 'react';
import '../styles/footer.css'


function Footer() {

  return (
    <>
      <div className='footer'>
        <h1 className='footwords'>© Synapses Caps 2024</h1>
      </div>

    </>
  );
};

export default Footer;
