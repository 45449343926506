import React, { useState } from 'react';

const FAQ = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const faqData = [
    {
      question: 'What is Psilocybin?', answer: 'Psilocybin is a naturally occurring alkaloid found in over a hundred species of mushrooms, mainly in the Psilocybe genus. It is a prodrug that gets converted in the body to its active metabolite, psilocin, which closely resembles the structure of serotonin'
    },
    {
      question: 'How does Psilocybin work in the body?', answer: 'Psilocybin is converted into psilocin in the stomach or via first-pass metabolism, which is more lipophilic and can cross the blood-brain barrier. The active metabolite, psilocin, activates 5HT receptors leading to the release of neurotransmitters and changes in brain function.'
    },
    {
      question: 'What are the potential benefits of Psilocybin treatment?', answer: 'Clinical studies indicate that psilocybin has shown benefits in treating addiction, major depressive disorder, and end-of-life mood disorders. It has demonstrated efficacy in reducing symptoms of depression, aiding in smoking cessation, and decreasing heavy drinking and alcohol consumption.'
    },
    {
      question: 'What are the challenges associated with Psilocybin experimentation?', answer: 'Challenges include adverse reactions such as delirium, panic attacks, and symptoms similar to schizophrenia, along with difficulties in conducting double-blind studies due to the noticeable effects of psilocybin compared to placebos.'
    },
    {
      question: 'How does Psilocybin affect mental health disorders?', answer: 'Psilocybin has been found to decrease depression and suicidal behaviors, increase memory and learning, and regulate defective reward pathways in patients suffering from depression and suicidal ideation, primarily through its effects on serotonin and glutamate receptors.'
    },
    {
      question: 'What are the known adverse reactions to Psilocybin?', answer: 'Some individuals may experience delirium, panic attacks, depersonalization, extreme distress, elevated blood pressure, nausea, vomiting, and other symptoms similar to those of schizophrenia.'
    },
    {
      question: 'What makes Psilocybin a promising treatment for addiction?', answer: 'Psilocybin has shown significant results in addiction treatment, with studies reporting high rates of smoking cessation and decreases in heavy drinking and alcohol consumption days among participants.'
    },
    {
      question: 'Can Psilocybin help with major depressive disorder?', answer: 'Yes, clinical trials have shown that psilocybin treatment leads to a significant reduction in depression scores compared to standard treatments, with benefits lasting several weeks post-treatment.'
    },
    {
      question: 'What role does Psilocybin play in treating end-of-life mood disorders?', answer: 'For patients facing end-of-life issues, psilocybin therapy has been associated with clinically significant drops in depression and anxiety, improving the quality of life during difficult times.'
    },
    {
      question: 'What are the future considerations for Psilocybin research and therapy?', answer: 'Future studies need to address the challenges of conducting double-blind experiments, minimize subject selection biases, and continue exploring the therapeutic potential of psilocybin while considering its cultural and legal complexities.'
    },
    // Add additional FAQs here as needed
  ];


  const toggleAnswer = (index) => {
    setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
  };

  return (
    <div style={styles.container}>
      {faqData.map((faq, index) => (
        <div key={index} style={styles.faqItem}>
          <button style={styles.question} onClick={() => toggleAnswer(index)}>
            <span style={styles.icon}>
              {activeQuestionIndex === index ? '-' : '+'}
            </span>
            {faq.question}
          </button>
          {activeQuestionIndex === index && (
            <p style={styles.answer}>{faq.answer}</p>
          )}
        </div>
      ))}
    </div>
  );
};


const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'left',
  },
  header: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  faqItem: {
    marginBottom: '10px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '10px',
    color: 'white',
  },
  question: {
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '10px 0',
    color: 'white',
    display: 'flex', // Added for aligning icon and text
    alignItems: 'center', // Align items vertically
  },
  icon: {
    marginRight: '10px', // Space between icon and text
    fontSize: '20px', // Slightly larger icon size
    fontWeight: 'bold',
  },
  answer: {
    fontSize: '16px',
    padding: '5px 0 10px 0',
    color: 'white'
  },
};

export default FAQ;