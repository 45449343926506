import React from 'react';
import '../styles/testimonial.css'; // Import the CSS for styling
import backgroundImage from '../assets/Mush.png'; // Importing the image


const testimonials = [
  { id: 1, name: "Dominic Angelo", text: "…it allowed me to connect with myself more than I was able to before, it will whether I like it or not force me to face my trauma and past head on, while also gently guiding me through it overtime and being able to basically rewrite portions of my brain that I never thought would heal. product changed my life! Highly recommend." },
  { id: 2, name: "Tai", text: "There’s no side effects with mushrooms. Prescription anti depressants have plenty. I didn’t like feeling numb on antidepressants. Microdosing has lifted me out of the dark abyss. I haven’t had any issues with mushrooms. They have really just made me feel lighter and inspired me to do things, accomplish things and made me want to be around people again." },
  { id: 3, name: "Iris", text: "The ah ha moments click into place more. What’s profound is the medicine itself – still feeling the energy of the medicine even at a low dose, and actually feeling the brain rewire with mild headaches." },
  { id: 4, name: "Eric", text: "At work I feel a lot more focused and productive. When I’m at home I feel more relaxed and at peace. Also after 25+ years of being a smoker I quit cold turkey." },
  { id: 5, name: "Plur", text: "…I think it really helped me to make big decisions about who I actually wanted to be and how I wanted to present myself, which definitely led to me being so confident and unconcerned with people not liking me now, because at least I’m being my authentic self." },
  { id: 6, name: "Chaz P ", text: "Another profound realization was that fear responses were dominating my psyche. Without that heightened fear response I felt an immense weight lifted from my body and mind. I would rather experience life and death through a fearless state of mind than a fearful one." },
  { id: 7, name: "Julie", text: "Having medication refractory depression I had resigned to a life of misery and struggle. Since finding mushrooms I am happier, I take initiative to reach goals, I’m much more creative, I can meditate now and I’m more social. At 52 I finally feel like I’m living my life not just tolerating it." },
  { id: 8, name: "Matt Skiba", text: "If anyone out there is suffering from pain pill addiction, try microdosing psilocybin." },
  { id: 9, name: "Jessica Homhai", text: "I’ve noticed a significant improvement from when I started 3 months ago. My cognitive function feels sharper, and I’m able to retain information more easily. It’s been a game changer." },
  { id: 10, name: "Willie W", text: "I have been on ADD stimulant medications since I was 10ths old and always felt like a slave to my medication. I started microdosing with psilocybin 3 years ago in an effort to get away from big pharma. I have had better results with improved concentration using psilocybin then big pharma and haven’t had to take any stimulant medications since starting my protocol." }
];

const Testimonial = () => {
  return (
    <div className="testimonial-page">
      {testimonials.map(testimonial => (
        <div key={testimonial.id} className="testimonial">
          <div className="testimonial-text">"{testimonial.text}"</div>
          <div className="testimonial-name">- {testimonial.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Testimonial;
