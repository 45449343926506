import React, { useState, useEffect } from 'react';
import '../styles/carousel.css';
import sl1 from '../assets/prodC.png';
import sl2 from '../assets/testC.png';
import sl3 from '../assets/faqC.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';




const themeD = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#000000',
            darker: '#fff',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
    typography: {
        fontSize: 13, // Change this value to adjust the font size
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: '#e0ac5a', // Change this value to your desired hover color
                    },
                    width: '80%',
                },
            },
        },
    },
});

const Carousel = () => {
    const slides = [
        {
            id: 1,
            imgUrl: sl1,
            description: 'Elevate your daily routine!',
            url: './product',
            buttonText: 'Product'
        },
        {
            id: 2,
            imgUrl: sl3,
            description: 'Unlock your potential, naturally.',
            url: './faq',
            buttonText: 'FAQ'
        },
        {
            id: 3,
            imgUrl: sl2,
            description: 'Empower your mind.',
            url: './testimonial',
            buttonText: 'Testimonial'
        }
    ];


    const [currentSlide, setCurrentSlide] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);

    const handleNextSlide = () => {
        setAutoPlay(false);
        setCurrentSlide((currentSlide + 1) % slides.length);
        setTimeout(() => setAutoPlay(true), 5000);
    };

    const handlePreviousSlide = () => {
        setAutoPlay(false);
        setCurrentSlide(
            currentSlide === 0 ? slides.length - 1 : currentSlide - 1
        );
        setTimeout(() => setAutoPlay(true), 5000);
    };

    useEffect(() => {
        if (autoPlay) {
            const autoPlaySlide = setInterval(() => {
                setCurrentSlide((currentSlide + 1) % slides.length);
            }, 5000);
            return () => clearInterval(autoPlaySlide);
        }
    }, [currentSlide, autoPlay]);

    useEffect(() => {
        const preloadImages = (srcArray) => {
            srcArray.forEach((src) => {
                const newImage = new Image();
                newImage.src = src;
            });
        };

        // Preload images on component mount
        preloadImages([sl1, sl2, sl3]);
    }, []);

    return (
        <div className="slider-container">
            <div className="slide">
                <img className='infoImage' src={slides[currentSlide].imgUrl} alt={`Slide ${currentSlide + 1}`} />
                <p className='info-words' >{slides[currentSlide].description}</p>
                <ThemeProvider theme={themeD} >
                    <Button className='info-button' color='primary' variant='contained' onClick={() => window.location.href = slides[currentSlide].url}>{slides[currentSlide].buttonText}</Button>
                </ThemeProvider>
            </div>
            <div className='button-container'>
                <button className='prev' onClick={handlePreviousSlide}>&#10094;</button>
                <button className='next' onClick={handleNextSlide}>&#10095;</button>
            </div>
        </div>
    );

};

export default Carousel;