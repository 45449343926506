import { Navbar, NavbarBrand } from 'react-bootstrap';
import Button from '@mui/material/Button';
import React from 'react';
import Logo from '../assets/SynapsesW.png';
import LogoI from '../assets/BrainL.png';
import '../styles/navbar.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";



const theme3 = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#fff',
      darker: '#80808080',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#000000',
    },
  },
  typography: {
    fontSize: 16, // Change this value to adjust the font size
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#e0ac5a', // Change this value to your desired hover color

          },
        },
      },
    },
  },

});


function NavbarComponent() {

  return (
    <>
      <Navbar expand="lg">
        <NavbarBrand href='/'>
          <img src={LogoI} alt='logo' className='logo3'></img>
          <img src={Logo} alt='logo' className='logo2'></img>
        </NavbarBrand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/'}>Home</Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/product'} >Product</Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/testimonial'} >Testimonial</Button>
          </ThemeProvider>
          <ThemeProvider theme={theme3}>
            <Button color='primary' onClick={() => window.location.href = '/faq'} >FAQ</Button>
          </ThemeProvider>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
};

export default NavbarComponent;
