import React from "react";
import Carousel from '../components/Carousel';
import '../styles/home.css'
import Button from '@mui/material/Button';
import { Grid, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import bImage from '../assets/micro.jpeg';
import cImage from '../assets/scIntegrity.PNG';
import dImage from '../assets/premiumQ.png';
import ContactForm from "../components/ContactForm";




const theme2 = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#fff',
            darker: '#80808080',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#000000',
        },
    },
    typography: {
        fontSize: 16, // Change this value to adjust the font size
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    width: '80%',
                    maxWidth: '300px',

                    marginTop: '20px',
                    marginBottom: '38px',
                    '&:hover': {
                        color: '#e0ac5a', // Change this value to your desired hover color
                        borderColor: '#e0ac5a', // Optional: Changes the border color on hover
                    },
                },
            },
        },
    },
});

const theme3 = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#fff',
            darker: '#80808080',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#000000',
        },
    },
    typography: {
        fontSize: 16, // Change this value to adjust the font size
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    border: '1px solid #ffffff', // Adds a white border to the button
                    width: '80vw',
                    maxWidth: '1100px',
                    marginTop: '-25px',
                    marginBottom: '30px',
                    '&:hover': {
                        color: '#e0ac5a', // Change this value to your desired hover color
                        borderColor: '#e0ac5a', // Optional: Changes the border color on hover
                    },
                },
            },
        },
    },
});


function Home() {

    return (
        <>
            <Carousel />

            <div className="news-container">
                <div className="news-item">
                    <h2>Transparent Innovation</h2>
                    <p>We believe in transparency every step of the way. From our ingredients to our processes to our results, we provide clear and accurate information so you can make informed decisions about your health.</p>
                </div>
                <div className="news-item">
                    <h2>Personal Empowerment</h2>
                    <p>Our microdose capsules are designed to seamlessly integrate into your daily routine, giving you a convenient and discreet way to take control of your wellness journey.</p>
                </div>
                <div className="news-item">
                    <h2>Holistic Healing</h2>
                    <p>We embrace a holistic approach to health and wellness, recognizing that true well-being encompasses mind, body, and spirit. With psilocybin and lion's mane as your allies, the possibilities for growth and healing are limitless. Unlock your potential, naturally.</p>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <ThemeProvider theme={theme3}>
                    <Button color='primary' onClick={() => window.location.href = '/product'}>View Our Product</Button>
                </ThemeProvider>
            </div>

            <div style={{ position: 'relative', margin: '0 auto', maxWidth: '1100px', textAlign: 'center' }}>

                <Grid container spacing={0} justifyContent="center" style={{ marginTop: '-15px' }}>
                    <Grid item xs={12} md={6} style={styles.gridItem}>
                        <img src={cImage} alt="Related Content" style={styles.contentImage} />
                    </Grid>
                    <Grid item xs={12} md={6} style={styles.gridItem}>
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Scientific Integrity</h2>
                            <p>Our microdose capsules are developed based on the latest scientific research, blending modern knowledge with ancient wisdom to deliver optimal results for your mind and body.</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={0} justifyContent="center" style={{ marginBottom: '5px' }}>
                    <Grid item xs={12} md={6} style={styles.gridItem} order={{ md: '1', lg: '2' }}>
                        <img src={dImage} alt="Related Content" style={styles.contentImage} />
                    </Grid>
                    <Grid item xs={12} md={6} style={styles.gridItem} order={{ md: '2', lg: '1' }}>
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Premium Quality</h2>
                            <p>We source only the highest quality organic ingredients and adhere to stringent manufacturing standards to ensure that every capsule is pure, potent, and safe.</p>
                        </div>
                    </Grid>

                </Grid>
            </div>

            <Box sx={{
                position: 'relative',
                width: 'auto',
                height: '450px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '-5px',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${bImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.3, // Adjust background image opacity here
                    zIndex: -1, // Ensure the overlay is behind the content

                }, '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0)', // Adjust the opacity (last parameter) here
                    zIndex: -2, // Ensure the overlay is behind the background image
                },
            }}>
                <Typography variant="h4" component="h1" sx={{ fontSize: '26px', color: '#fff', textAlign: 'center', mt: 2, margin: '30px', marginBottom: '10px' }}>
                    Microdosing Made Simple
                </Typography>
                <Typography variant="p1" component="p1" sx={{ color: '#fff', textAlign: 'center', mt: 2, margin: '10px', width: '82vw', maxWidth: '1100px' }}>
                    Unlock your potential and elevate your well-being with Synapses Caps innovative microdose capsules. Our carefully crafted formulations combine the therapeutic benefits of psilocybin and lion's mane to support mental clarity, cognitive function, and emotional balance. Welcome to a new era of wellness.
                </Typography>
                <ThemeProvider theme={theme2}>
                    <Button color='primary' onClick={() => window.location.href = '/faq'}>FAQ</Button>
                </ThemeProvider>
            </Box>

            <ContactForm />
        </>
    );
}

const styles = {
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'center',
    },
    contentImage: {
        width: '95%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: '20px auto',
    },
    textContent: {
        width: '95%',
        margin: '20px auto',
        border: '3px solid rgba(255,255,255, 0.6)',
        padding: '20px',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'left',
        fontSize: '26px',
    },
};


export default Home;
