import React from 'react';
import '../styles/product.css'; // Importing the CSS for styling
import Product from '../assets/Caps.jpg'
import CL1 from '../assets/CL1.png';
import CL2 from '../assets/CL2.png';
import CL3 from '../assets/CL3.png';
import AL from '..//assets/allergen.png'
import { Grid } from '@mui/material';

import ContactForm from "../components/ContactForm";

import ImageGrid from '../components/ImgageGrid';

const ProductDisplayPage = () => {
  return (
    <>
      <Grid container spacing={0} justifyContent="center" style={{ position: 'relative', margin: '0 auto', marginTop: '-15px', maxWidth: '1400px', textAlign: 'center' }}>

        <Grid item xs={12} md={6} style={styles.gridItem}>
          <img src={Product} alt="Related Content" style={styles.contentImage} />

        </Grid>
        <Grid item xs={12} md={6} style={styles.gridItem}>
          <div style={styles.textContent}>
            <h2 style={styles.sectionHeading}>Synapses Caps</h2>
            <p style={{ fontSize: '17px' }}>Unlock the potential of your mind with our premium Micro Dose Capsules, meticulously crafted to combine the power of Psilocybin and Lion's Mane mushrooms. Designed for those seeking clarity, creativity, and enhanced cognitive function, our capsules offer a natural synergy that promotes mental well-being and focus.
              Harnessing the therapeutic benefits of Psilocybin, renowned for its ability to promote introspection and expand consciousness, alongside Lion's Mane, celebrated for its neuroprotective and cognitive enhancing properties.
            </p>
          </div>
        </Grid>
      </Grid>

      <div style={{ textAlign: 'center', marginTop: '0px', marginBottom: '40px' }}>
        <img style={{
          width: '88vw',
          maxWidth: '1050px',
          height: 'auto',
        }}

          src={AL} />
      </div>
      <div style={{ position: 'relative', margin: '0 auto', maxWidth: '1200px', textAlign: 'center' }}>

        <div style={{ textAlign: 'left', marginLeft: '6%', marginRight: '6%', marginTop: '20px' }}>
          <h1 style={{ color: '#e79a00' }}>
            Paul Stamet's Protocol
          </h1>
          <p1 style={{ color: '#e79a00' }}>
            Paul Stamets stack is a combination of psilocybin, lions mane mushroom and niacin (vitamin B3). This micro-dose schedule is 4 days on and 3 days off during the week, for a recommended 4-8 weeks with 2-4 week reset / break between cycles
          </p1>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '40px' }}>
          <img style={{
            width: '88vw',
            maxWidth: '1000px',
            height: 'auto',
          }}

            src={CL1} />
        </div>
        <div style={{ textAlign: 'left', marginLeft: '6%', marginRight: '6%', marginTop: '20px' }}>
          <h1 style={{ color: '#e79a00' }}>
            Microdosing Institute Protocol
          </h1>
          <p1 style={{ color: '#e79a00' }}>
            This microdose schedule is every other day during the week, for a recommended 4-8 weeks with 2-4 week reset / break between cycles. "Every second day can increase the effectiveness of microdosing for medical purposes or psychological challenges such as depression, social anxiety, ADHD / ADD, migranes, or cluster headaches." -Microdosing Institute
          </p1>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '40px' }}>
          <img style={{
            width: '88vw',
            maxWidth: '1000px',
            height: 'auto',
          }}

            src={CL2} />
        </div>
        <div style={{ textAlign: 'left', marginLeft: '6%', marginRight: '6%', marginTop: '20px' }}>
          <h1 style={{ color: '#e79a00' }}>
            Dr. Fadimans Protocol
          </h1>
          <p1 style={{ color: '#e79a00' }}>
            Dr. Fadiman protocol (also known as the beginners protocol) is the most well known. A weekly schedule is 3 times a week, with 2 rest days in between each micro-dose for a month, with a recommended 2-4 week rest / break.
          </p1>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <img style={{
            width: '88vw',
            maxWidth: '1000px',
            height: 'auto',
          }}

            src={CL3} />
        </div>
      </div>
      <div style={{ marginTop: '45px' }} />

      <div className="news-container">
        <div className="news-item">
          <h2>Ingredients</h2>
          <p>Each capsule contains a precise blend of Psilocybin and Lion's Mane mushrooms.</p>
        </div>
        <div className="news-item">
          <h2>Synergistic Effects</h2>
          <p>Psilocybin offers introspective and consciousness-expanding experiences, while Lion's Mane promotes neuroprotection and cognitive enhancement. Together, they create a powerful synergy for mental well-being and focus. </p>
        </div>
        <div className="news-item">
          <h2>Quality Assurance</h2>
          <p>We grow, source, and test all our mushrooms to ensure purity, potency, and safety. Our products come with certificates of analysis, guaranteeing quality and authenticity.</p>
        </div>
        <div className="news-item">
          <h2>Organic</h2>
          <p>We prioritize organic cultivation methods, ensuring our mushrooms are free from pesticides and harmful chemicals.</p>
        </div>
        <div className="news-item">
          <h2>No Fillers</h2>
          <p>Our capsules contain only the purest ingredients, with no fillers or additives added. You get the full benefits of Psilocybin and Lion's Mane without any unnecessary substances.</p>
        </div>
        <div className="news-item">
          <h2>Sustainable</h2>
          <p>We are committed to sustainability throughout our production process, from cultivation to packaging. Our capsules offer a natural and eco-friendly solution for mental well-being.</p>
        </div>
        <div className="news-item">
          <h2>Usage</h2>
          <p>Take one capsule daily, or as recommended by a healthcare professional. Consult with a physician before use, especially if pregnant, nursing, or taking medications.</p>
        </div>
        <div className="news-item">
          <h2>Legal Compliance</h2>
          <p>Please check your local laws regarding the use of psilocybin-containing products.</p>
        </div>
      </div>
      <div style={{ marginBottom: '-35px' }} />
      <ContactForm />


    </>

  );
};

const styles = {
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'center',
  },
  contentImage: {
    width: '500px',
    height: '500px',
    maxWidth: '90vw',
    maxHeight: '60vh',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '8px',
    margin: '20px auto',
  },
  textContent: {
    width: '95%',
    margin: '20px auto',
    padding: '10px',
    borderRadius: '8px',
    color: 'white',
    backgroundColor: 'transparent',
  },
  textContentC: {
    width: '100%',
    maxWidth: '400px',
    margin: '20px auto',
    borderRadius: '8px',
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontSize: '21px'
  },
  sectionHeading: {
    color: 'white',
    textAlign: 'left',
    fontSize: '30px',
  },
};

export default ProductDisplayPage;
